// src/Dashboard.js

import React, { useState,useEffect  } from 'react';
import './App.css';
import logo from './logo.png';
import ThemeDialog from './themeDialog';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard({ username, empowerCount:initialEmpowerCount }) {
    const logoUrl = `https://oss.hitale.top/icon/${username}.jpg`;
    const apiUrl = process.env.REACT_APP_API_URL;
    // 对话框显示状态
  const [showDialog, setShowDialog] = useState(false);

  // 输入框状态
  const [selectedTheme, setSelectedTheme] = useState(''); // 主题输入框初始值
  const [wechatIds, setWechatIds] = useState(''); // 微信号输入框值
  const [themes, setThemes] = useState([]);//主题列表
  const [empowerCount, setEmpowerCount] = useState(initialEmpowerCount);

  const handleThemeClick = () => {
    setShowDialog(true); // 显示底部对话框
};

const handleThemeSelect = (theme) => {
    setSelectedTheme(theme); // 更新选择的主题
    setShowDialog(false); // 关闭对话框
};

  useEffect(() => {
    // 从服务器获取用户主题列表
    fetch(`${apiUrl}/api/themes/${username}`)
        .then(response => response.json())
        .then(data => {
            // 处理主题数据，假设数据格式为数组
        const formattedThemes = data.themes.map(theme => {
            const [themeName, author] = theme.split('-');
            return { themeName, author };
          });
          setThemes(formattedThemes);
        })
        .catch(error => {
            console.error('获取主题列表失败:', error);
        });
}, [username]);
    
  const handleAuthorizeClick = async () => {
    const wechatIdList = wechatIds.split(',').filter(id => id.trim() !== '');

    if (selectedTheme === '' || wechatIdList.length === 0) {
      toast.error('请选择主题并输入至少一个微信号'); // 弹窗提示
      return;
    }

    let failedAuthorizations = 0;

    for (const wechatId of wechatIdList) {
      try {
        const response = await fetch(`${apiUrl}/api/authorize`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selectedTheme, wechatId, username }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success(`授权成功`); // 显示授权成功消息
        } else {
          failedAuthorizations++;
          toast.error(`${data.message}`); // 显示错误消息
        }
      } catch (error) {
        failedAuthorizations++;
        console.error('请求出错:', error);
        toast.error(`授权请求失败: ${wechatId}`);
      }
    }

    if (failedAuthorizations === 0) {
      // 如果所有授权都成功，获取最新的授权次数
      try {
        const userResponse = await fetch(`${apiUrl}/api/userInfo?username=${username}`);
        const userData = await userResponse.json();

        if (userResponse.ok) {
          setEmpowerCount(userData.Empower); // 更新授权次数
        } else {
          toast.error('获取授权次数失败');
        }
      } catch (error) {
        console.error('获取授权次数请求出错:', error);
        toast.error('获取授权次数请求失败');
      }
    }

    setWechatIds(''); // 清空微信号输入框
  };

  return (
    <div className="Dashboard">
      <header className="App-header">
        <img src={logoUrl} className="Header-logos" alt="logo" />
        <div className="user-info">
          <span className="login-status">{username}</span>
          <span className="authorization-info">
            授权剩余：{empowerCount}
          </span>
        </div>
      </header>
      <div className="logo-container">
        <img src={logo} className="App-logos" alt="logo" />
        <h1 className="App-title">WeTheme授权网</h1>
      </div>
      <div className="form-container">
        {/* 选择主题输入框 */}
        <input
           type="text"
           id="themeName"
           className="input-field"
           placeholder="请选择主题"
           value={selectedTheme}
           onClick={handleThemeClick}
           readOnly
          />
          {/* 微信号输入框 */}
          <input
            type="text"
            placeholder="请输入微信号"
            className="input-field"
            value={wechatIds}
            onChange={(e) => setWechatIds(e.target.value)} // 输入时更新状态
          />
        <button className="login-button" onClick={handleAuthorizeClick}>
          确认授权
        </button>
      </div>
      {/* 底部对话框组件 */}
      <ThemeDialog
                show={showDialog}
                onClose={() => setShowDialog(false)}
                themes={themes}
                onSelect={handleThemeSelect}
            />
    </div>
  );
  
}

export default Dashboard;
