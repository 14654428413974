// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// 获取 root 节点，React 应用将挂载到此节点上
const rootElement = document.getElementById('root');

// 使用 ReactDOM 创建根渲染器并渲染 App 组件
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
