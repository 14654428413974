// src/themeDialog.js

import React, { useState } from 'react';
import './ThemeDialog.css'; // 确保样式文件被正确引入

function ThemeDialog({ show, onClose, themes, onSelect }) {
  const [searchTerm, setSearchTerm] = useState(''); // 搜索框的状态

  if (!show) {
    return null;
  }

  // 根据搜索词过滤主题列表
  const filteredThemes = themes.filter((theme) =>
    theme.themeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="theme-dialog-overlay" onClick={onClose}>
      <div className="theme-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="dialog-content">
          <h2>选择主题</h2>
          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索主题"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // 更新搜索框的状态
          />
          <ul className="theme-list">
            {filteredThemes.map((theme, index) => (
              <li
                key={index}
                onClick={() => onSelect(`${theme.themeName}-${theme.author}`)}
              >
                <div className="theme-item">
                  <span className="theme-name">{theme.themeName}</span>
                  <span className="theme-author">作者：{theme.author.replace('.wt','')}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ThemeDialog;
